<template>
  <Layout>
    <WithPage v-slot="{ isPageLoading, thePage }" :slug="pageSlug">
      <transition name="fade" mode="out-in">
        <BaseLoader v-if="isPageLoading" bg-color="#cfebdf" color="#FFF"
          >Loading...</BaseLoader
        >
        <div v-else-if="!thePage" class="not-found">
          <BasePageSection bg-color="#cfebdf" fluid>
            <VLayout justify-center>
              <VFlex xs6>
                <h3 class="r-headline"
                  >Sorry, but we couldn't find that page.
                  <BaseButton flat link small color="green darken-2" to="/"
                    >Home</BaseButton
                  >
                </h3>
              </VFlex>
            </VLayout>
          </BasePageSection>
          <BaseThemeComponent name="SectionLocations" />
        </div>
        <div v-else :class="`page-container-${thePage.slug}`">
          <BasePageHeader
            v-if="thePage.contents.header_title || thePage.contents.header_img"
            :bg-image="thePage.contents.header_img"
            :title="thePage.contents.header_title"
          />

          <BaseThemeComponent
            v-if="thePage.contents.show_home_hero"
            name="SectionHomeHero"
          />

          <BaseThemeComponent
            v-if="thePage.contents.show_locations"
            id="home-locations"
            name="SectionLocations"
          />

          <BaseThemeComponent
            v-if="thePage.contents.show_order_online"
            name="SectionOrderOnline"
          />

          <BaseThemeComponent
            v-if="thePage.contents.show_instagram"
            name="SectionInstagram"
          />

          <BaseThemeComponent
            v-if="
              thePage.contents.ns_1_title ||
                thePage.contents.ns_1_description ||
                thePage.contents.ns_1_img
            "
            name="PageSectionNarrative"
            section-id="1"
            full-bleed-bg
          >
            <BasePageContent content-key="ns_1_description" tag="p" />

            <BaseButtonOutline
              v-if="thePage.contents.ns_1_button"
              :outline-color="thePage.contents.ns_1_button_outline_color"
              line-weight="1"
              class="ml-0"
              :to="thePage.contents.ns_1_button_link"
            >
              {{ thePage.contents.ns_1_button }}
            </BaseButtonOutline>
          </BaseThemeComponent>

          <BasePageSectionImageBreak
            v-if="thePage.contents.img_break_1"
            :bg-image="thePage.contents.img_break_1"
          ></BasePageSectionImageBreak>

          <BaseThemeComponent
            v-if="
              thePage.contents.ns_2_title ||
                thePage.contents.ns_2_description ||
                thePage.contents.ns_2_img
            "
            name="PageSectionNarrative"
            section-id="2"
            full-bleed-bg
          >
            <BasePageContent content-key="ns_2_description" tag="p" />

            <BaseButtonOutline
              v-if="thePage.contents.ns_2_button"
              :outline-color="thePage.contents.ns_2_button_outline_color"
              line-weight="1"
              class="ml-0"
              :to="thePage.contents.ns_2_button_link"
            >
              {{ thePage.contents.ns_2_button }}
            </BaseButtonOutline>
          </BaseThemeComponent>

          <BasePageSection
            v-if="thePage.contents.show_contact_form"
            full-bleed-bg
          >
            <VLayout row py-4 wrap>
              <VFlex xs12 sm5 offset-sm0 md5 offset-md1>
                <BasePageContent
                  content-key="contact_form_title"
                  default-content="We'd love to hear from you!"
                  tag="h2"
                  tag-class="header-3 form-greeting pb-4 mb-4"
                />
                <p
                  ><img
                    :src="thePage.contents.contact_form_img"
                    :alt="thePage.contents.contact_form_img_alt"
                /></p>
              </VFlex>
              <VFlex xs12 sm4 offset-sm2>
                <ContactForm />
              </VFlex>
            </VLayout>
          </BasePageSection>
        </div>
      </transition>
    </WithPage>
  </Layout>
</template>

<script>
import Layout from '@utils/get-layout'
import ContactForm from '@components/ContactForm'

export default {
  name: 'Page',
  components: {
    ContactForm,
    Layout,
  },
  page: {
    title: 'The Daily Beet',
    meta: [
      {
        name: 'description',
        content:
          'A healthy cafe serving salads, grain bowls, cold-pressed juices, and more.',
      },
    ],
  },
  props: {
    pageSlug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss">
@import '@design';

.form-greeting {
  // TODO (ESS) : Dont hardcode
  font-family: 'motor', serif !important;
  border-bottom: 2px solid $color-db-purple;
}
</style>
