var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('WithPage',{attrs:{"slug":_vm.pageSlug},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isPageLoading = ref.isPageLoading;
var thePage = ref.thePage;
return [_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(isPageLoading)?_c('BaseLoader',{attrs:{"bg-color":"#cfebdf","color":"#FFF"}},[_vm._v("Loading...")]):(!thePage)?_c('div',{staticClass:"not-found"},[_c('BasePageSection',{attrs:{"bg-color":"#cfebdf","fluid":""}},[_c('VLayout',{attrs:{"justify-center":""}},[_c('VFlex',{attrs:{"xs6":""}},[_c('h3',{staticClass:"r-headline"},[_vm._v("Sorry, but we couldn't find that page. "),_c('BaseButton',{attrs:{"flat":"","link":"","small":"","color":"green darken-2","to":"/"}},[_vm._v("Home")])],1)])],1)],1),_c('BaseThemeComponent',{attrs:{"name":"SectionLocations"}})],1):_c('div',{class:("page-container-" + (thePage.slug))},[(thePage.contents.header_title || thePage.contents.header_img)?_c('BasePageHeader',{attrs:{"bg-image":thePage.contents.header_img,"title":thePage.contents.header_title}}):_vm._e(),(thePage.contents.show_home_hero)?_c('BaseThemeComponent',{attrs:{"name":"SectionHomeHero"}}):_vm._e(),(thePage.contents.show_locations)?_c('BaseThemeComponent',{attrs:{"id":"home-locations","name":"SectionLocations"}}):_vm._e(),(thePage.contents.show_order_online)?_c('BaseThemeComponent',{attrs:{"name":"SectionOrderOnline"}}):_vm._e(),(thePage.contents.show_instagram)?_c('BaseThemeComponent',{attrs:{"name":"SectionInstagram"}}):_vm._e(),(
            thePage.contents.ns_1_title ||
              thePage.contents.ns_1_description ||
              thePage.contents.ns_1_img
          )?_c('BaseThemeComponent',{attrs:{"name":"PageSectionNarrative","section-id":"1","full-bleed-bg":""}},[_c('BasePageContent',{attrs:{"content-key":"ns_1_description","tag":"p"}}),(thePage.contents.ns_1_button)?_c('BaseButtonOutline',{staticClass:"ml-0",attrs:{"outline-color":thePage.contents.ns_1_button_outline_color,"line-weight":"1","to":thePage.contents.ns_1_button_link}},[_vm._v(" "+_vm._s(thePage.contents.ns_1_button)+" ")]):_vm._e()],1):_vm._e(),(thePage.contents.img_break_1)?_c('BasePageSectionImageBreak',{attrs:{"bg-image":thePage.contents.img_break_1}}):_vm._e(),(
            thePage.contents.ns_2_title ||
              thePage.contents.ns_2_description ||
              thePage.contents.ns_2_img
          )?_c('BaseThemeComponent',{attrs:{"name":"PageSectionNarrative","section-id":"2","full-bleed-bg":""}},[_c('BasePageContent',{attrs:{"content-key":"ns_2_description","tag":"p"}}),(thePage.contents.ns_2_button)?_c('BaseButtonOutline',{staticClass:"ml-0",attrs:{"outline-color":thePage.contents.ns_2_button_outline_color,"line-weight":"1","to":thePage.contents.ns_2_button_link}},[_vm._v(" "+_vm._s(thePage.contents.ns_2_button)+" ")]):_vm._e()],1):_vm._e(),(thePage.contents.show_contact_form)?_c('BasePageSection',{attrs:{"full-bleed-bg":""}},[_c('VLayout',{attrs:{"row":"","py-4":"","wrap":""}},[_c('VFlex',{attrs:{"xs12":"","sm5":"","offset-sm0":"","md5":"","offset-md1":""}},[_c('BasePageContent',{attrs:{"content-key":"contact_form_title","default-content":"We'd love to hear from you!","tag":"h2","tag-class":"header-3 form-greeting pb-4 mb-4"}}),_c('p',[_c('img',{attrs:{"src":thePage.contents.contact_form_img,"alt":thePage.contents.contact_form_img_alt}})])],1),_c('VFlex',{attrs:{"xs12":"","sm4":"","offset-sm2":""}},[_c('ContactForm')],1)],1)],1):_vm._e()],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }